import React, { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { StoreProps } from 'Models/dimensions'
import { Segment } from 'DimensionsFiltersTypes'

import { flattenChannelChildren, flattenCustomerSegments } from '../helpers'
import { capitalizeFirstLetter } from 'Utils'

export type props = {
  segment: Segment
  className?: string
}

const OPERATORS = {
  EQUAL: '=',
  IN_THE_FOLLOWING: 'in the following',
}

const DimensionInterfaceLabel: React.FC<props> = ({ segment, className }) => {
  const { type, operator, value, name, count } = segment
  const { customerGroup } = useStoreState((state: { dimensions: StoreProps }) => state.dimensions)

  const recursionTree = (label: any, data: any) => {
    let result: any = []
    data.forEach((item) => {
      let childKeys: any = []
      if (item.children) {
        childKeys = recursionTree(label, item.children)
      }
      if (label.includes(item.key)) {
        result.push(...[item.title, ...childKeys])
      } else {
        result.push(...childKeys)
      }
    })

    return result
  }
  const renderSegmentLabel = useMemo(() => {
    const segments = customerGroup?.length ? flattenCustomerSegments(customerGroup) : []
    const currentSegment = segments?.find((segment) => segment?.code === type)

    const operatorLabel =
      currentSegment?.operator?.find((opr) => opr?.code === operator)?.name || operator

    const valueLabel = value?.toString()?.includes(',')
      ? value
          ?.toString()
          ?.split(',')
          ?.map(
            (item) =>
              currentSegment?.values?.find((v) => v?.code === item)?.name ||
              Number(item)?.toLocaleString()
          )
          .join(', ')
      : currentSegment?.values?.find((v) => v?.code === value)?.name ||
        value ||
        Number(value)?.toLocaleString()

    const fortmatValueLabel =
      value?.toString()?.split(',')?.length < 5
        ? value?.toString()?.split(',')?.join(', ')
        : `${value?.toString()?.split(',')?.length} items`

    return `${currentSegment?.name || type} ${operatorLabel ? operatorLabel : ''}  ${
      operatorLabel
        ? segment?.type == 'GENDER' || segment?.type == 'PHONE'
          ? valueLabel
          : fortmatValueLabel
        : ''
    }`
  }, [customerGroup, type, operator, value])

  return <div className={className}>{renderSegmentLabel}</div>
}

export default DimensionInterfaceLabel

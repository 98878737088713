import React from 'react'

import CustomerGroup from './Customer'

const DimensionsGroups = ({
  activeDimension,
  segmentOptions,
}: {
  activeDimension: any
  segmentOptions: any
}) => {
  return (
    <div className="flex w-full h-full overflow-scroll DimensionGroups">
      <div className="relative w-full overflow-x-hidden overflow-y-scroll">
        <CustomerGroup />
      </div>
    </div>
  )
}

export default DimensionsGroups

import axios from 'Utils/axios'
import React, { useEffect, useState } from 'react'

export default function useFetchDimensions() {
  const [options, setOptions] = useState([])
  const [keyword, setKeyword] = useState('')
  const fetchDimensions = async () => {
    try {
      const resp = await axios.get('/audience-dimensions/dimensions', {
        params: {
          dimension_code: 'APP_VIEW_PRODUCT_DETAIL',
          keyword: keyword || '',
          per_page: 100,
        },
      })
      setOptions(resp.data.data.map((op) => ({ label: op?.name, value: op?.code, ...op })))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchDimensions()
  }, [keyword, keyword === ''])

  return {
    options,
    setKeyword,
  }
}

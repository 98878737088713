import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Select } from 'antd'

import CriteriaGroup from './CriteriaGroup'
import { formatRespTimeType } from 'Pages/Audiences/Detail/helpers'

const Criteria = ({ defaultDimensions }: { defaultDimensions?: any }) => {
  const { criteria_groups, predefined_segment } = useStoreState((state) => state.customerSegments)
  const { addDimensionsGroup } = useStoreActions((action: any) => action.customerSegments)

  const temp_criteria_groups = (defaultDimensions ?? criteria_groups)?.map((group) => {
    return {
      condition: group?.condition,
      dimension: {
        condition: group?.dimension?.condition,
        segments: group.dimension.segments.map((segment) => {
          return {
            ...segment,
          }
        }),
      },
    }
  })

  return (
    <div id="Criteria ">
      <div>
        {temp_criteria_groups?.map((gr, index) => (
          <div
            className="mb-4"
            key={index}
            style={{ cursor: `${predefined_segment ? 'not-allowed' : 'pointer'}` }}
          >
            <CriteriaGroup index={index} group={gr} />
          </div>
        ))}
        {!!temp_criteria_groups[0]?.dimension?.segments.length && (
          <div onClick={addDimensionsGroup} className="bg-gray_1 mt-4">
            <span className="text-brand_primary font-semibold cursor-pointer">
              + New Condition Group
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Criteria

import React, { useState, createContext, useReducer, useEffect, useMemo } from 'react'
import { Modal, Button, Alert } from 'antd'
import axios from 'axios'

import { Criteria, Dimension } from 'DimensionsFiltersTypes'

import DimensionsGroups from './DimensionGroups'
import DimensionInterface from './DimensionInterface'
import { dimensionReducer, criteriaDraftToDimensions } from './helpers'
import { createProductDimension } from 'Services/products'
let cancelTokenSource = axios.CancelToken.source()

export type props = {
  isUuid?: boolean
  isPopupVisible: boolean
  syncedConditions?: boolean
  value: Dimension[]
  setIsPopupVisible: (isPopupVisible: boolean) => void
  onSave: (newDimension, filter_uuid?: string) => void
  activeDimension?: string
  segmentOptions?: []
}

export interface EditingSegmentType extends Dimension {
  index: number
}

export const dimensionPopupContext = createContext<{
  dimensionsDraft: Dimension[]
  editingDimension: EditingSegmentType | null
  quickSelectPhysicalStore: string[]
  setDimensionsDraft: React.Dispatch<{ type: string; payload?: { [key: string]: any } }>
  setEditingDimension: React.Dispatch<React.SetStateAction<EditingSegmentType | null>>
  setQuickSelect: React.Dispatch<string[]>
}>({
  dimensionsDraft: [],
  editingDimension: null,
  quickSelectPhysicalStore: [],
  setDimensionsDraft: () => {},
  setEditingDimension: () => {},
  setQuickSelect: () => {},
})

const DimensionsPopup: React.FC<props> = ({
  isPopupVisible,
  syncedConditions,
  setIsPopupVisible,
  onSave,
  value,
  activeDimension,
  segmentOptions,
  isUuid = false,
}) => {
  const [dimensionsDraft, setDimensionsDraft] = useReducer(dimensionReducer, value)
  const [editingDimension, setEditingDimension] = useState<EditingSegmentType | null>(null)
  const [quickSelectPhysicalStore, setQuickSelect] = useState<string[]>([])
  const [errors, setErrors] = useState('')
  useEffect(() => {
    if (isPopupVisible) setDimensionsDraft({ type: 'reset', payload: { dimensions: value } })
  }, [isPopupVisible])

  const fromDimensionsToCriteria = (dimensions: Dimension[]): Criteria[] => {
    return dimensions.map((dimension) => ({
      dimension: { ...dimension, condition: 'AND' },
      condition: dimension.condition,
    }))
  }

  const onClearAll = () => {
    setDimensionsDraft({ type: 'clear' })
    setEditingDimension(null)
  }

  const onClose = () => {
    setIsPopupVisible(false)
    setEditingDimension(null)
  }

  return (
    <Modal
      maskClosable={false}
      closable={false}
      width={1300}
      footer={null}
      onCancel={onClose}
      visible={isPopupVisible}
      destroyOnClose
      wrapClassName="DimensionFilterPopup"
    >
      <dimensionPopupContext.Provider
        value={{
          dimensionsDraft,
          setDimensionsDraft,
          editingDimension,
          setEditingDimension,
          quickSelectPhysicalStore,
          setQuickSelect,
        }}
      >
        <div className="flex items-center justify-between p-4 border-b DimensionFilterPopup-header-btns">
          <div>
            <span className="text-xl font-semibold">Filter by</span>
            <span className="mx-4 text-border">|</span>
            <span className="cursor-pointer text-brand_primary" onClick={onClearAll}>
              Clear All
            </span>
          </div>
          <div>
            <Button size="small" className="DimensionFilterPopup-cancel-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button
              // disabled={!!!dimensionsDraft.length}
              size="small"
              className="DimensionFilterPopup-save-btn"
              type="primary"
              onClick={() => {
                !isUuid && onSave(dimensionsDraft)
                setEditingDimension(null)
                setIsPopupVisible(false)
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="p-4 border-b">
          <div className="flex flex-wrap w-full gap-2">
            <DimensionInterface syncedConditions={syncedConditions} />
          </div>
        </div>
        <div>
          <div className="flex flex-wrap w-full gap-2 " style={{ height: '60vh' }}>
            <DimensionsGroups activeDimension={activeDimension} segmentOptions={segmentOptions} />
          </div>
        </div>
      </dimensionPopupContext.Provider>
    </Modal>
  )
}

export default DimensionsPopup

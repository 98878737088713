import Axios from 'axios'
import { useEffect, useState } from 'react'
const { REACT_APP_OL_API_USERNAME, REACT_APP_OL_API_PASSWORD } = process.env
export default function usePromotionDetail(selectedId: string | undefined) {
  const [promotion, setPromotion] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const { REACT_APP_OL_API_PROMOTION_URL } = process.env

  useEffect(() => {
    const fetchPromotionById = async () => {
      setLoading(true)
      try {
        const url = `${REACT_APP_OL_API_PROMOTION_URL}/v1/promotions/externals/kariba/promotions/${selectedId}`
        const authConfig = {
          auth: {
            username: REACT_APP_OL_API_USERNAME || '',
            password: REACT_APP_OL_API_PASSWORD || '',
          },
        }
        const resp = await Axios.get(url, authConfig)
        setPromotion(resp.data) // Assuming the response directly contains the promotion data
      } catch (error) {
        console.error('Failed to fetch promotion by ID', error)
      } finally {
        setLoading(false)
      }
    }

    if (selectedId) {
      fetchPromotionById()
    } else {
      setPromotion(null) // Reset promotion when selectedId becomes undefined
    }
  }, [selectedId])

  return {
    promotion,
    loading,
  }
}

import React, { useEffect, useState } from 'react'
import { List, Avatar, Typography } from 'antd'
import { RightOutlined, LayoutOutlined } from '@ant-design/icons'

import Config from './NodeConfigs'
import { CurrentType, DataType } from 'CustomerJourneyModule'
import axios from 'Utils/axios'
import { NODE_TYPES } from 'Containers/CustomerJourneyDesign/constants'
import { useStoreActions } from 'easy-peasy'
const { Text } = Typography

interface Props {
  current: CurrentType
  data: any
  isRunning: boolean
  parentNode: any
  nodeAttribute: boolean
  value: any
  isPopoverVisible: boolean
  onUpdateNode: (value: any) => void
  onAddNewNode: (type: string, value: any) => void
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const Body: React.FC<Props> = (props) => {
  const { data, setCurrent, current, isRunning, parentNode, nodeAttribute } = props
  const { setState } = useStoreActions((action: any) => action.customerSegments)

  const onItemClicked = (item: DataType) => {
    setCurrent({ ...item, data: item.data, previous: current })
  }
  const [channels, setChannels] = useState([])

  const fetchChannels = async () => {
    try {
      const resp = await axios.get('/customer-journeys/channels')
      setChannels(
        resp.data?.map((channel) => {
          return {
            ...channel,
            icon: (
              // <div style={{ width: '25px', height: '25px' }}>
              <LayoutOutlined size={25} />
              // </div>
            ),
          }
        })
      )
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    fetchChannels()
  }, [])

  const optionsFilter = () => {
    if (current.key === 'channels') {
      return channels
    }
    if (current.key === 'conditions') {
      return data
    }

    // if previous node is channel => hide channel option
    // if (parentNode.parentType === 'channel') {
    //   return data.filter((item: DataType) => item.key !== 'channels')
    // }

    // if previous node is wait => hide wait option
    if (parentNode.nodeType === 'wait') {
      return data.filter((item: DataType) => item.nodeType !== 'wait')
    }

    // if previous node is check_attr, condition item_price_discount_alert = true => only show channel option
    if (
      parentNode.nodeType === 'checkUserAttr' &&
      nodeAttribute === true &&
      parentNode.value.segments.find(
        (segment: any) => segment.type === 'item_price_discount_alert' && segment.value === 'TRUE'
      )
    ) {
      return data.filter((item: DataType) => item.key === 'channels')
    }

    return data
  }

  return (
    <div className={isRunning ? 'is-running w-full' : 'w-full'}>
      {Array.isArray(data) ? (
        <List
          itemLayout="horizontal"
          dataSource={optionsFilter()}
          className="CJPopoverBody"
          renderItem={(item: DataType) => (
            <div className="w-full" onClick={() => onItemClicked(item)}>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={item.icon} />}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text>{item.name}</Text>
                      {item.data && <RightOutlined />}
                    </div>
                  }
                />
              </List.Item>
            </div>
          )}
        />
      ) : (
        <Config {...props} />
      )}
    </div>
  )
}

export default Body

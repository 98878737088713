import React from 'react'

// channels
import WebPush from 'Components/CJChannels/Webpush'
import SMS from 'Components/CJChannels/SMS'
import EmailTabs from 'Components/CJChannels/Email'
import Popup from 'Components/CJChannels/Popup'
import AppPopup from 'Components/CJChannels/AppPopup'
import Kaipass from 'Components/CJChannels/Kaipass'
import { HaraSocialModalSF, HaraSocialModal } from 'Components/CJChannels/HaraSocial'

// others
import ABTesting from './ABTesting'
import Wait from './Wait'

// conditions
import UserAttribute from './UserAttribute'
import Reachability from './Reachability'
import SelectAudience from './SelectAudience'
import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import AppPush from 'Components/CJChannels/AppPush'
import Entrance from './Entrance'
import PromotionNode from './PromotionNode'
import CouponNode from './CouponNode'
import WebPopup from 'Components/CJChannels/WebPopup'
import AppInPage from 'Components/CJChannels/AppInPage'
import WebInPage from 'Components/CJChannels/WebInPage'
import ZaloGroup from 'Components/CJChannels/ZNSByTemplateId'
import AiCheckNode from './AiCheckNode'
import NewWaitIn from './NewWaitIn'
import OutOfPromotionNode from './OutofPromotionNode'
interface Props {
  current: CurrentType
  data: any
  isRunning: boolean
  value: any
  isPopoverVisible: boolean
  onAddNewNode: (type: string, value: any) => void
  onUpdateNode: (value: any) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const Config: React.FC<Props> = (props) => {
  const { data, onUpdateNode, onAddNewNode, setIsPopoverVisible } = props
  const handleEmailSubmit = async (values: any) => {
    onAddNewNode && (await onAddNewNode('email', values))
    onUpdateNode && (await onUpdateNode(values))
  }

  const renderConfig = (type: string) => {
    const values = props?.current.value

    switch (type) {
      // channels
      case 'web_push':
        return <WebPush {...props} />
      case 'sms':
        return <SMS {...props} />
      case 'email':
        return (
          <EmailTabs
            {...props}
            withModal
            values={values}
            onSubmit={handleEmailSubmit}
            showPreview
          />
        )
      case 'popup':
        return <Popup {...props} />
      case 'appPopup':
        return <AppPopup {...props} />
      case 'webPopup':
        return <WebPopup {...props} />
      case 'appInPage':
        return <AppInPage {...props} />
      case 'webInPage':
        return <WebInPage {...props} />
      case 'znsByTemplateId':
        return <ZaloGroup {...props} />
      case 'kaipass':
        return <Kaipass {...props} />

      case 'harasocial':
        return <HaraSocialModal {...props} setIsPopoverVisible={setIsPopoverVisible} />

      case 'hrs_store_farming':
        return <HaraSocialModalSF {...props} setIsPopoverVisible={setIsPopoverVisible} />
      case 'ol_app':
        return <AppPush {...props} setIsPopoverVisible={setIsPopoverVisible}></AppPush>

      // others
      case 'waitIn':
        return <NewWaitIn {...props} />
      case 'promotion':
        return <PromotionNode {...props} />
      case 'outOfPromotion':
        return <OutOfPromotionNode {...props} />
      case 'coupon':
        return <CouponNode {...props} />
      case 'wait':
        return <Wait {...props} />
      case 'split':
        return <ABTesting {...props} />
      case 'aiCheck':
        return <AiCheckNode {...props} />

      // conditions
      case 'checkUserAttr':
        return <UserAttribute {...props} />
      case 'checkReachability':
        return <Reachability {...props} />
      // case 'entrance':
      //   return <Entrance {...props} />
      case 'checkAudienceSegment':
        return <SelectAudience {...props} />
      case 'entrance':
        return <Entrance {...props} />
      default:
        return 'Not available at the moment'
    }
  }

  return <div className="NodeConfigComponent">{renderConfig(data)}</div>
}

export default Config

import React from 'react'
import { Select } from 'antd'
import './index.scss'

const { Option } = Select
interface props {
  value: string | undefined
  onChange: (value: string) => void
  options?: { label: string; value: string }[]
  placeholder?: string
}
const SingleSelect: React.FC<props> = ({ value, options, placeholder, onChange }) => {
  return (
    <Select
      className="singleSelect-wrapper w-full rounded"
      placeholder={placeholder}
      showArrow={true}
      value={value || undefined}
      style={{ height: 40 }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      dropdownClassName="CommonInputs-single-select-dropdown"
    >
      {options?.map((value, index) => {
        return (
          <Option key={index} value={value?.value}>
            {value?.label}
          </Option>
        )
      })}
    </Select>
  )
}

export default SingleSelect

import { CustomerGroup, CustomerItem, ChannelGroup } from 'DimensionsFiltersTypes'
import { flatMap, flatten } from 'lodash'
import { Dimension, Criteria } from 'DimensionsFiltersTypes'

export const dimensionReducer = (state, action) => {
  switch (action.type) {
    case 'addChannel': {
      if (!state.length) {
        return [
          {
            ...action.payload.dimension,
            condition: state.length ? action.payload.dimension.condition : '',
          },
        ]
      } else {
        let checkExit = false
        const NewData = [...state]
        const mappingState = NewData.map((item) => {
          if (item?.segments[0].type == action?.payload.dimension.segments[0].type) {
            checkExit = true
            return {
              ...item,
              segments: [
                { ...item?.segments[0], value: action.payload.dimension.segments[0].value },
              ],
            }
          } else {
            return item
          }
        })
        if (!checkExit) {
          return [
            ...state,
            {
              ...action.payload.dimension,
              condition: state.length ? action.payload.dimension.condition : '',
            },
          ]
        } else {
          return mappingState
        }
      }
    }
    case 'add':
      return [
        ...state,
        {
          ...action.payload.dimension,
          condition: state.length ? action.payload.dimension.condition : '',
        },
      ]
    case 'update':
      return state.map((dimension: Dimension, index) => {
        if (index === action.payload.index) {
          return action.payload.newDimension
        }
        return dimension
      })
    case 'addSegment':
      return action.payload
    case 'remove':
      const temp = state.filter((dimension: Dimension, index) => index !== action.payload.index)
      if (temp[0]) temp[0].condition = ''
      return temp
    case 'clear':
      return []
    case 'reset':
      return action.payload.dimensions
    default:
      return state
  }
}

export const computeCustomerNestedGroup = (ancestor) => {
  return ancestor?.reduce((_, currentItem, index, ancestorArr) => {
    // check item with format " - "
    if (currentItem?.name?.includes(' - ')) {
      const parentName = currentItem.name.match(/.+(?= -)/)[0]
      const parentObject = { name: parentName, code: parentName, nestedGroups: [currentItem] }
      const parentObjInAncestor = ancestorArr?.find((item) => item?.name === parentName)

      // remove current item from ancestor array
      ancestorArr[index] = null

      // if parent arr not existed yet in ancestor arr, push it into to ancestor array and return new ancestor arr
      if (!parentObjInAncestor) {
        ancestorArr.push(parentObject)
        return ancestorArr.filter((item) => item)
      }

      // if parent arr existed, push item into to its parent array
      parentObjInAncestor.nestedGroups.push(currentItem)
    }

    // return new ancestor arr
    return ancestorArr.filter((item) => item)
  }, [])
}

export function flattenCustomerSegments(group: CustomerGroup[]): CustomerItem[] {
  let result: CustomerItem[] = []
  flatMap(group, ({ dimensions }) => {
    result = flatten([...result, dimensions]).filter((segment) => segment)
  })

  return result
}

export function flattenChannelChildren(group: ChannelGroup[]) {
  let result: any = []
  const noChildren = group[0].tree.tree.filter((item) => !item.children)
  result = [...result, ...noChildren]
  flatMap(group[0].tree.tree, ({ children }) => {
    result = flatten([...result, children]).filter((segment) => segment)
  })

  return result
}

export const criteriaDraftToDimensions = (criDraft) => {
  const temp: any = []
  for (let i = 0; i < criDraft.length; i++) {
    temp.push(criDraft[i]?.dimension)
  }
  return temp
}

import React from 'react'

// Operator mapping
const operatorSymbols = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  IS_BETWEEN: 'between', // Special handling needed
  IS_NOT_BETWEEN: 'not between', // Special handling needed
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
  STARTS_WITH: 'starts with',
  DOES_NOT_START_WITH: 'does not start with',
  ENDS_WITH: 'ends with',
  DOES_NOT_END_WITH: 'does not end with',
  IN_THE_FOLLOWING: 'in the following',
  NOT_IN_THE_FOLLOWING: 'not in the following',
}

const toTitleCase = (str) => {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase
    ?.split('_') // Split the string by underscores
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize the first letter of each word
    ?.join(' ') // Join the words with a space
}

// Function to format conditions
export const formatCartValueConditions = (conditions) => {
  return conditions
    ?.map((condition) => {
      const operator = operatorSymbols[condition?.operator]
      if (!operator) {
        throw new Error('Unknown operator')
      }
      if (operator === 'between' || operator === 'not between') {
        // Special handling for "between" and "not between"
        return `${toTitleCase(condition?.type)} ${operator} ${condition?.value} ${
          condition?.timeOperator
        } ${condition?.time_value}`
      }
      return `${toTitleCase(condition?.type)} ${operator} ${condition?.value || ''} ${
        condition?.timeOperator || ''
      } ${condition?.time_value || ''} ${condition?.count ? `count ${condition?.count}` : ''}`
    })
    ?.join(' and ')
}

export const formatCartValueConditionsSegments = (conditions) => {
  return conditions
    ?.map((condition, conditionIndex) => {
      const segmentText = condition?.dimension?.segments
        .map((segment, segmentIndex) => {
          const operator = operatorSymbols[segment?.operator]
          let formattedSegment
          if (!operator) {
            formattedSegment = `${toTitleCase(segment?.type) || ''}`
          }

          if (operator === 'between' || operator === 'not between') {
            formattedSegment = `${toTitleCase(segment?.type)} ${operator || ''} ${segment?.value}`
          } else {
            formattedSegment = `${toTitleCase(segment?.type)} ${operator || ''} ${
              segment?.value || ''
            }`
          }

          // Only add the condition if it's not the first segment within the dimension
          if (segmentIndex > 0) {
            formattedSegment = `${condition.dimension.condition.toLowerCase()} ${formattedSegment}`
          }
          return formattedSegment
        })
        .join(' ')

      // Only add the condition if it's not the first dimension
      if (conditionIndex > 0) {
        return `${condition.condition.toLowerCase()} ${segmentText}`
      }

      return segmentText
    })
    ?.join(' ')
}

export function camelCaseToNormalText(camelCaseText) {
  // Add a space before any uppercase letter and convert the first letter to uppercase
  let result = camelCaseText
    ?.replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    ?.replace(/^./, function (str) {
      return str?.toUpperCase()
    }) // Capitalize the first letter

  // Replace "Entrance" with "Event"
  result = result?.replace(/Entrance/g, 'Event')

  return result
}
export function formatCamelCaseToTitle(str) {
  // Add a space before all capital letters and capitalize the first letter
  return str
    ?.replace(/([A-Z])/g, ' $1') // Add space before capital letters
    ?.replace(/^./, function (str) {
      return str?.toUpperCase()
    }) // Capitalize the first letter
    ?.trim() // Remove leading/trailing spaces
}

import { CurrentType } from 'CustomerJourneyModule'
import axios from 'Utils/cj-axios'
import { Button, Select } from 'antd'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: { channel: string; params?: any }
  onUpdateNode: any
}

export default function Entrance({ value, onUpdateNode }: Props) {
  const [entrances, setEntrances] = useState<{ code: string; name: string; params?: any }[]>([])

  const fetchEntrances = async () => {
    try {
      const resp = await axios.get('/customer-journeys/entrances')
      console.log(resp)
      setEntrances(resp.data)
    } catch (err) {
      //
    }
  }

  const onSubmit = (values: { channel: string; params?: any }) => {
    onUpdateNode && onUpdateNode(values)
  }

  useEffect(() => {
    fetchEntrances()
  }, [])

  return (
    <div className="EntranceComponent">
      <Formik
        initialValues={value ? value : { channel: entrances?.[0]?.code }}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item">
              <Select
                style={{ width: '100%' }}
                value={values.channel}
                onChange={(value) => {
                  setFieldValue('channel', value)
                  if (entrances?.find((item) => item.code === value)?.params) {
                    setFieldValue('params', entrances?.find((item) => item.code === value)?.params)
                  } else {
                    setFieldValue('params', [])
                  }
                }}
              >
                {entrances?.map((item: any) => {
                  return <Option value={item.code}>{item.name}</Option>
                })}
              </Select>
            </div>

            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Select, Typography, Button } from 'antd'
import { Formik, Form } from 'formik'
import usePromotionListing from '../PromotionNode/usePromotionListing'
import usePromotionDetail from '../PromotionNode/usePromotionDetail'
import { useDebounce } from 'Hooks'

const { Title } = Typography
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: { promotionId: string }
  onUpdateNode: any
}

const CouponNode: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = { promotionId: '' }
  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearchTerm = useDebounce(searchTerm, 500)

  const onSubmit = (values: { promotionId: string }) => {
    onAddNewNode && onAddNewNode('coupon', values)
    onUpdateNode && onUpdateNode(values)
  }

  const { data, loading, hasMore, loadMore } = usePromotionListing({
    params: {
      mode: 'coupon',
      has_coupon_pool: true,
      name: debounceSearchTerm,
    },
  })
  const { promotion: promotionData } = usePromotionDetail(value?.promotionId)

  useEffect(() => {
    if (data?.length) {
      console.log(data)
    }
  }, [data])

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
    console.log(bottom, hasMore, loading)
    if (bottom && hasMore && !loading) {
      loadMore()
    }
  }

  const onSearch = (keyword) => {
    setSearchTerm(keyword)
  }

  return (
    <div className="ReachabilityComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item">
              <Select
                showSearch
                value={values.promotionId}
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select a promotion"
                optionFilterProp="children"
                onPopupScroll={handleScroll}
                onSearch={onSearch}
                onChange={(value) => setFieldValue('promotionId', value)}
                filterOption={false} // Let the server handle the filtering
              >
                {promotionData ? (
                  <Option className="hidden" value={promotionData?.id} key={promotionData?.id}>
                    {promotionData.name}
                  </Option>
                ) : undefined}

                {data.map((promotion) => (
                  <Option key={promotion.id} value={promotion.id}>
                    {promotion.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default CouponNode

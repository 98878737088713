import React, { useState, useEffect, Fragment, useContext, createContext } from 'react'
import { Popover, Modal } from 'antd'
import _ from 'lodash'
import { useStoreActions, useStoreState } from 'easy-peasy'

import VirtualNode from './VirtualNode'
import CJPopover from 'Components/CJPopover'
import {
  NODE_TYPES,
  CJ_NODE_WIDTH,
  CJ_NODE_HEIGHT,
} from 'Containers/CustomerJourneyDesign/constants'
import SquareNode from './SquareNode'
import RoundNode from './RoundNode'
import NodeMenu from './NodeMenu'
import './index.scss'
export const CJModalPopup = createContext()

const CJNode = (props) => {
  const {
    bgColor,
    value,
    level,
    nodeType,
    computeNodesTree,
    childrenNodes,
    node,
    status,
    x: left,
    y: top,
    changeTool,
    deleteNode,
    isRunning,
    nodeUuid,
  } = props
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isAddPopoverVisible, setIsAddPopoverVisible] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [currentConfig, setCurrentConfig] = useState({
    ...NODE_TYPES[nodeType],
    data: nodeType,
    value: value,
    nodeUuid: nodeUuid,
  })
  const { CJTree } = useStoreState((state) => state.customerJourney)
  const { setCJStates } = useStoreActions((actions) => actions.customerJourney)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const modalVisibleOff = () => {
    setIsModalVisible(false)
  }
  const modalVisibleOn = () => {
    setIsModalVisible(true)
  }
  const CJModalValue = {
    isModalVisible,
    modalVisibleOff,
    modalVisibleOn,
  }
  function collectNodes(node, result = []) {
    // Only add the node to the result if it's not of type 'virtual'
    if (node.nodeType !== 'virtual') {
      result.push(node)
    }

    // If the current node has children, recurse through each child
    if (node.childrenNodes && node.childrenNodes.length > 0) {
      node.childrenNodes.forEach((child) => {
        collectNodes(child, result)
      })
    }

    // Return the accumulated result array
    return result
  }

  function findMissingAlias(node) {
    // Collect all nodes first
    const allNodes = collectNodes(node)

    // Extract aliases and convert to numbers for easy comparison
    const aliases = allNodes
      .map((node) => parseInt(node.alias)) // Convert alias to number
      .filter((alias) => !isNaN(alias)) // Ensure we only keep valid numbers
      .sort((a, b) => a - b) // Sort aliases in ascending order

    // Check for missing alias in the sequence
    for (let i = 0; i < aliases.length; i++) {
      if (aliases[i] !== i + 1) {
        // If alias is missing, return the missing alias
        return i + 1
      }
    }

    // If no alias is missing, return the next in sequence
    return aliases.length + 1
  }

  // hide popovers
  let CJContainer = document.querySelector('.cj-layout-content')
  const hidePopovers = (e) => {
    const targetNode =
      e.target.attributes.type?.value == nodeType && e.target.attributes.level.value == level
    // !targetNode && isMenuVisible && setIsMenuVisible(false)
    !targetNode && isPopoverVisible && setIsPopoverVisible(false)
    !targetNode && isAddPopoverVisible && setIsAddPopoverVisible(false)
  }

  CJContainer.addEventListener('click', hidePopovers, { once: true })
  // right click
  CJContainer.addEventListener('contextmenu', hidePopovers)

  CJContainer.addEventListener(
    'mousedown',
    () => {
      changeTool('auto')
    },
    { once: true }
  )

  useEffect(() => {
    setCurrentConfig({
      ...NODE_TYPES[nodeType],
      data: nodeType,
      value: value,
      nodeUuid: nodeUuid,
    })
  }, [nodeType])

  useEffect(() => {
    if (Array.isArray(value)) {
      currentConfig.value && value.splice(0, value.length, currentConfig.value)
    }
    value && typeof value === 'object' && Object.assign(value, currentConfig.value)
  }, [currentConfig])

  useEffect(() => {
    changeTool('none')
    setIsMenuVisible(false)
  }, [isPopoverVisible, isAddPopoverVisible])

  const onAddNewNode = (type, value) => {
    setCJStates({ key: 'isCJModified', value: true })
    const alias = findMissingAlias(CJTree)
    computeNodesTree(node, type, value, alias)
    setIsPopoverVisible(false)
  }

  const onUpdateNode = async (updatedValue) => {
    setCJStates({ key: 'isCJModified', value: true })
    setCurrentConfig({ ...currentConfig, value: updatedValue })
    setIsPopoverVisible(false)
  }

  const renderNode = () => {
    // if (['wait', 'split'].includes(nodeType)) return <RoundNode {...props} bgColor={bgColor} />
    if (nodeType === 'virtual') return false
    return <SquareNode nodeValue={currentConfig.value} {...props} bgColor={bgColor} />
  }

  const nodeMenuClick = (menuKey) => {
    if (menuKey === 'delete') {
      Modal.confirm({
        title: (
          <span>
            Delete step <span className="node-name">{NODE_TYPES[node.nodeType].name}</span> ?
          </span>
        ),
        content: 'Items below this step will be deleted as well!',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        className: 'node-prompt',
        onOk() {
          deleteNode(node)
        },
        onCancel() {},
      })
    }
  }
  let centerPoint
  let menuRadius
  if (['split'].includes(node.nodeType)) {
    centerPoint = { x: 86, y: 25 }
    menuRadius = 60
  }

  return (
    <CJModalPopup.Provider value={CJModalValue}>
      <g
        onContextMenu={(e) => {
          e.preventDefault()
          setIsMenuVisible(!isMenuVisible)
          setIsPopoverVisible(false)
        }}
        transform={`translate(${left}, ${top})`}
        overflow="visible"
      >
        {isMenuVisible && (
          <foreignObject x={CJ_NODE_WIDTH} width={1} height={1}>
            <NodeMenu
              onClick={nodeMenuClick}
              isMenuVisible={isMenuVisible}
              node={node}
              centerPoint={centerPoint}
              menuRadius={menuRadius}
            />
          </foreignObject>
        )}
        <Popover
          placement="right"
          visible={isPopoverVisible}
          overlayClassName="CJPopover"
          trigger="click"
          onVisibleChange={(visible) => setIsPopoverVisible(!isPopoverVisible)}
          content={
            <CJPopover
              isRunning={isRunning}
              // status={status}
              parentNode={node?.parentNode}
              isPopoverVisible={isPopoverVisible}
              setIsPopoverVisible={setIsPopoverVisible}
              currentConfig={currentConfig}
              value={value}
              onUpdateNode={onUpdateNode}
            />
          }
        >
          <g>{renderNode()}</g>
        </Popover>
        {nodeType === 'virtual' && (
          <VirtualNode
            setIsAddPopoverVisible={setIsAddPopoverVisible}
            isAddPopoverVisible={isAddPopoverVisible}
            onAddNewNode={onAddNewNode}
            {...props}
            bgColor={bgColor}
            isRunning={isRunning}
          ></VirtualNode>
        )}
      </g>
    </CJModalPopup.Provider>
  )
}

CJNode.defaultProps = {
  top: 0,
  left: 0,
  radius: 7,
  bgColor: 'FF7376',
}

export default CJNode

import React, { useEffect } from 'react'
import { Typography, Button } from 'antd'
import { connect } from 'react-redux'

import UserAttributeModal from './Modal2'
import Segmentation from 'Components/Segmentation'
import './index.scss'
const { Title } = Typography

interface Props {
  onAddNewNode: (subType: string, values: any) => void
  onUpdateNode: (values: any) => void
  value: any
  segmentation: any
  isPopoverVisible: boolean
  resetSegmentation: any
  fetchDimensions: any
  setSegmentationState: any
  dimensions: any
}

const UserAttribute: React.FC<Props> = ({
  onAddNewNode,
  onUpdateNode,
  value,
  segmentation,
  isPopoverVisible,
  resetSegmentation,
  fetchDimensions,
  setSegmentationState,
  dimensions,
}) => {
  const onSubmit = (values: any) => {
    onAddNewNode && onAddNewNode('checkUserAttr', values)
    onUpdateNode && onUpdateNode(values)
  }

  const handleFetchSegments = async () => {
    setSegmentationState({ key: 'isFetching', value: true })
    await resetSegmentation()
    await fetchDimensions({ type: 'check_user_attr' })
    value && setSegmentationState({ key: 'data', value: [value] })
    setSegmentationState({ key: 'isFetching', value: false })
  }

  useEffect(() => {
    if (isPopoverVisible) {
      handleFetchSegments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  return (
    <div className="UserAttributeComponent">
      <div className="form-item">
        <Title className="field-title">Attribute(s):</Title>

        <Segmentation
          dimensions={dimensions}
          isVisible={isPopoverVisible}
          dimensionModal={UserAttributeModal}
          segmentation={segmentation}
        ></Segmentation>
      </div>
      <div
        className="form-item"
        style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
      >
        <Button onClick={() => onSubmit(segmentation[0])} type="primary">
          OK
        </Button>
      </div>
    </div>
  )
}

interface StateProps {
  segmentation: any
}

export default connect(
  (state: StateProps) => ({
    segmentation: state.segmentation.data,
    visible: state.segmentation.dimensionModalVisible,
    dimensions: state.segmentation.dimensions,
  }),
  (dispatch: any) => ({
    toggleDimensionModal: dispatch.segmentation.toggleDimensionModal,
    fetchDimensions: dispatch.segmentation.fetchDimensions,
    setSegmentationState: dispatch.segmentation.setState,
    resetSegmentation: dispatch.segmentation.resetState,
  })
)(UserAttribute)

import React, { useEffect, useRef, useState } from 'react'
import { Select, Typography, Button, Popover } from 'antd'
import { Formik, Form } from 'formik'
import axios from 'Utils/cj-axios'

interface Props {
  onAddNewNode: any
  value: { type: string }
  onUpdateNode: any
}

const AiCheckNode: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = { type: '', subType: '', params: [] }
  const [aiChecks, setAiChecks] = useState<
    { name: string; code: string; options?: { name: string; code: string }[]; params?: string[] }[]
  >([])

  useEffect(() => {
    const fetchAiChecks = async () => {
      const resp = await axios.get('/customer-journeys/ai-suggestions')
      setAiChecks(resp.data)
    }

    fetchAiChecks()
  }, [])

  const onSubmit = (values: { type: string; subType?: string; params?: string[] }) => {
    onAddNewNode && onAddNewNode('aiCheck', values)
    onUpdateNode && onUpdateNode(values)
  }

  return (
    <div className="ReachabilityComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item gap-3">
              {aiChecks.map((ai) => {
                if (ai?.options?.length) {
                  return (
                    <div className=" cursor-pointer py-1" key={ai?.code}>
                      <Popover
                        placement="right"
                        content={
                          <div>
                            {ai?.options?.map((option) => (
                              <div
                                className=" cursor-pointer py-1"
                                key={option?.code}
                                onClick={() => {
                                  onSubmit({
                                    type: ai?.code,
                                    subType: option?.code,
                                    params: ai?.params || [],
                                  })
                                }}
                              >
                                {option?.name}
                              </div>
                            ))}
                          </div>
                        }
                      >
                        {ai.name}
                      </Popover>
                    </div>
                  )
                }
                return (
                  <div
                    className=" cursor-pointer py-1"
                    key={ai?.code}
                    onClick={() => {
                      onSubmit({
                        type: ai?.code,
                        params: ai?.params || [],
                      })
                    }}
                  >
                    {ai.name}
                  </div>
                )
              })}
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default AiCheckNode

import React, { useState } from 'react'
import { Select, Typography, Button, Input, DatePicker } from 'antd'
import { Formik, Form } from 'formik'
import usePromotionListing from './usePromotionListing'
import { useDebounce } from 'Hooks'
import usePromotionDetail from './usePromotionDetail'
import moment from 'moment'
import type { RadioChangeEvent } from 'antd'
import { Radio } from 'antd'
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'

const { Title } = Typography
const { Option } = Select

interface Props {
  onAddNewNode: any
  value?: { promotionId: string; wait_time?: number; time_format?: string; dateTime?: string }
  onUpdateNode: any
}

const PromotionNode: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = {
    promotionId: '',
    wait_time: '',
    time_format: '',
    dateTime: '',
  }
  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearchTerm = useDebounce(searchTerm, 500)
  const [dateTypeValue, setDateTypeValue] = useState(
    value?.time_format ? 'time_format' : value?.dateTime ? 'dateTime' : ''
  )

  const { data, loading, hasMore, loadMore } = usePromotionListing({
    params: {
      name: debounceSearchTerm,
    },
  })
  const { promotion: promotionData } = usePromotionDetail(value?.promotionId)

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
    if (bottom && hasMore && !loading) {
      loadMore()
    }
  }

  const onSearch = (keyword) => {
    setSearchTerm(keyword)
  }

  const onSubmit = (values: { promotionId: string }) => {
    console.log('values', values)
    onAddNewNode && onAddNewNode('promotion', values)
    onUpdateNode && onUpdateNode(values)
  }
  return (
    <div className="ReachabilityComponent">
      <Formik initialValues={{ ...defaultValue, ...value }} onSubmit={(values) => onSubmit(values)}>
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item">
              <Title className="field-title" level={5}>
                Promotion
              </Title>
              <Select
                showSearch
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select a promotion"
                optionFilterProp="children"
                onPopupScroll={handleScroll}
                onSearch={onSearch}
                value={values.promotionId}
                onChange={(value, option: any) => {
                  setFieldValue('promotionId', value)
                  setFieldValue('promotionName', option?.children)
                }}
                filterOption={false} // Let the server handle the filtering
              >
                {promotionData ? (
                  <Option className="hidden" value={promotionData?.id} key={promotionData?.id}>
                    {promotionData.name}
                  </Option>
                ) : undefined}

                {data.map((promotion) => (
                  <Option key={promotion.id} value={promotion.id}>
                    {promotion.name}
                  </Option>
                ))}
              </Select>
            </div>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                setDateTypeValue(e.target.value)
                if (e.target.value === 'time_format') {
                  setFieldValue('dateTime', '')
                } else if (e.target.value === 'dateTime') {
                  setFieldValue('time_format', '')
                  setFieldValue('wait_time', '')
                }
              }}
              value={dateTypeValue}
              className="flex flex-col"
            >
              <Radio value={'time_format'}>Time</Radio>
              {dateTypeValue === 'time_format' && (
                <div className="form-item">
                  <div className=" flex gap-2">
                    <Input
                      className="mr-2 w-1/2"
                      onChange={handleChange}
                      name="wait_time"
                      value={values.wait_time}
                    />
                    <Select
                      onChange={(value) => {
                        if (dateTypeValue === 'time_format') {
                          setFieldValue('time_format', value)
                          setFieldValue('dateTime', '')
                        }
                      }}
                      className="time-setter-format w-1/2"
                      value={values.time_format}
                    >
                      <Option value="min">mins</Option>
                      <Option value="hour">hours</Option>
                      <Option value="day">days</Option>
                    </Select>
                  </div>
                </div>
              )}
              <Radio value={'dateTime'}>Date time</Radio>
              {dateTypeValue === 'dateTime' && (
                <div className="form-item">
                  <DatePicker
                    showTime
                    format={'YYYY-MM-DDTHH:mm:ss'}
                    // defaultPickerValue={moment(value?.dateTime).format() as any}
                    value={values.dateTime ? moment(values.dateTime) : null}
                    // defaultValue={moment(value?.dateTime).format() as any}
                    onChange={(
                      value: DatePickerProps['value'] | RangePickerProps['value'],
                      dateString: [string, string] | string
                    ) => {
                      console.log('dateString', dateString)
                      if (dateTypeValue === 'dateTime') {
                        const utcDate = moment.tz(dateString, 'Asia/Bangkok').utc()
                        setFieldValue('dateTime', utcDate.format())
                        setFieldValue('time_format', '')
                        setFieldValue('wait_time', '')
                      }
                    }}
                    onOk={(value: DatePickerProps['value'] | RangePickerProps['value']) => {
                      console.log('onOk: ', value)
                    }}
                  />
                </div>
              )}
            </Radio.Group>

            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PromotionNode

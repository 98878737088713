import Axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
const { REACT_APP_OL_API_USERNAME, REACT_APP_OL_API_PASSWORD } = process.env
export default function usePromotionListing({ params }: { params?: Record<string, any> }) {
  const [data, setData] = useState<any[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    limit: 10,
  })
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const { REACT_APP_OL_API_PROMOTION_URL } = process.env
  useEffect(() => {
    setData([]) // Reset data when search term changes
    setPagination((prev) => ({ ...prev, currentPage: 1 }))
  }, [params?.name])

  const fetchPromotions = async (page = 1) => {
    setLoading(true)
    try {
      const resp = await Axios.get(
        `${REACT_APP_OL_API_PROMOTION_URL}/v1/promotions/externals/kariba/promotions?`,
        {
          auth: {
            username: REACT_APP_OL_API_USERNAME || '',
            password: REACT_APP_OL_API_PASSWORD || '',
          },
          params: {
            order: 'created_at',
            direction: 'desc',
            status: 'running,waiting',
            page: page,
            ...params,
          },
        }
      )
      setData((prevData) => [...(page === 1 ? [] : prevData), ...(resp.data.promotions || [])])
      setPagination({
        currentPage: resp.data.pagination.current_page,
        lastPage: resp.data.pagination.last_page,
        total: resp.data.pagination.total,
        limit: resp.data.pagination.limit,
      })
      setHasMore(resp.data.pagination.current_page < resp.data.pagination.last_page)
    } catch (error) {
      console.error('Failed to fetch promotions', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPromotions(pagination.currentPage)
  }, [pagination.currentPage, params?.name])

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      }))
    }
  }, [loading, hasMore])

  return {
    data,
    pagination,
    loading,
    hasMore,
    loadMore,
  }
}

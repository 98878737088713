import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Dimension as DimensionType } from 'DimensionsFiltersTypes'
import { Dimension as AudienceDimensionType } from 'CustomerSegmentTypes'

import DimensionFilter from '../DimensionFilters'
import { getDimensions, getDimensionsSegment } from 'Services/dimensions'

interface props {
  groupIndex?: number
  dimension?: AudienceDimensionType
}

const Dimension: React.FC<props> = ({
  groupIndex = 0,
  dimension = { segments: [], condition: '' },
}) => {
  const { onDimensionGroupChange } = useStoreActions((action: any) => action.customerSegments)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  return (
    <div className="flex flex-wrap gap-4">
      <div className="p-2 border border-border rounded w-full">
        <DimensionFilter
          activeDimension={dimension?.segments[0]?.type === 'SEGMENT' ? 'segment' : ''}
          syncedConditions
          emptyText={<span className="text-brand_primary cursor-pointer">Add Condition</span>}
          list={{ customer: { page: 'segment' } }}
          onSaveDimensions={(dimensions: DimensionType[]) => {
            onDimensionGroupChange({
              groupIndex,
              group: {
                dimension: {
                  segments: dimensions.map((dimension) => dimension.segments[0]),
                  condition: dimensions[1]?.condition || 'AND',
                },
                condition: 'AND',
              },
            })
          }}
          dimensions={dimension.segments.map((item) => ({
            segments: [item],
            condition: dimension.condition,
          }))}
          isPopupVisible={isPopoverVisible}
          setIsPopupVisible={setIsPopoverVisible}
        />
      </div>
    </div>
  )
}

export default Dimension

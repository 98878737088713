import React, { useCallback, useState, useContext, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'
import { Button, Popover, Alert, Tabs, Space, Modal } from 'antd'
import { StoreProps } from 'Models/dimensions'
import { CustomerItem } from 'DimensionsFiltersTypes'

import { computeCustomerNestedGroup } from '../../helpers'
import SegmentItem from './Customer.SegmentItem'
import SegmentScreen from './Customer.SegmentScreen'
import { dimensionPopupContext } from '../../Dimensions.Popup'
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons'
import '../../index.scss'
import Link from 'antd/lib/typography/Link'
import axios from 'Utils/cj-axios'

const { TabPane } = Tabs

const DimensionsCustomer = () => {
  const { setDimensionsDraft, editingDimension, setEditingDimension, dimensionsDraft } =
    useContext(dimensionPopupContext)

  const { criteria_groups } = useStoreState((state: any) => state.customerSegments)

  const [customerGroup, setCustomerGroup] = useState<any>()
  const fetchDimensions = async () => {
    try {
      const resp = await axios.get('/customer-journeys/interactions')
      setCustomerGroup([
        {
          group_name: '',
          group_code: '',
          dimensions: resp.data,
        },
      ])
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchDimensions()
  }, [])
  const [activeSegment, setActiveSegment] = useState<CustomerItem | undefined>(undefined)
  const renderCustomerGroup = useCallback((dim, clickable) => {
    // cloning array
    var segments = dim?.dimensions?.slice()

    return computeCustomerNestedGroup(segments)?.map((segment) => {
      // render nested groups
      if (segment.nestedGroups)
        return (
          <Popover
            overlayClassName="DimensionGroups"
            key={segment?.code}
            placement="right"
            content={
              <div>
                {segment.nestedGroups?.map((item) => (
                  <SegmentItem
                    disableClick={clickable}
                    key={item?.code}
                    segment={item}
                    onClick={() => setActiveSegment(item)}
                  />
                ))}
              </div>
            }
          >
            <div>
              <SegmentItem segment={segment} disableClick={clickable} />
            </div>
          </Popover>
        )

      return (
        <div className=" bg-gray-50 rounded">
          <SegmentItem
            disableClick={clickable}
            key={segment?.code}
            segment={segment}
            onClick={() => {
              if (segment?.operator?.length) {
                setActiveSegment(segment)
              } else {
                if (!!editingDimension) {
                  setDimensionsDraft({
                    type: 'update',
                    payload: {
                      index: editingDimension.index,
                      newDimension: {
                        segments: [
                          {
                            type: segment?.code,
                          },
                        ],
                        condition: dimensionsDraft[1]?.condition,
                      },
                    },
                  })
                } else {
                  setDimensionsDraft({
                    type: 'add',
                    payload: {
                      dimension: {
                        segments: [
                          {
                            type: segment?.code,
                          },
                        ],
                        condition: dimensionsDraft[1]?.condition || 'AND',
                      },
                    },
                  })
                }
              }
            }}
          />
        </div>
      )
    })
  }, [])

  useEffect(() => {
    if (editingDimension) {
      let screen: CustomerItem | undefined

      // eslint-disable-next-line  @typescript-eslint/no-unused-expressions
      customerGroup?.forEach((group) => {
        if (screen) return
        screen = group?.dimensions?.find(
          (dimension) => dimension.code === editingDimension?.segments[0]?.type
        )
      }, {})

      return setActiveSegment(screen)
    }
  }, [editingDimension])

  if (activeSegment)
    return (
      <SegmentScreen
        onOk={({ type, operator, value }) => {
          if (!!editingDimension) {
            setDimensionsDraft({
              type: 'update',
              payload: {
                index: editingDimension.index,
                newDimension: {
                  segments: [
                    {
                      type,
                      operator,
                      value,
                    },
                  ],
                  condition: dimensionsDraft[1]?.condition,
                },
              },
            })
          } else {
            setDimensionsDraft({
              type: 'add',
              payload: {
                dimension: {
                  segments: [
                    {
                      type,
                      operator,
                      value,
                    },
                  ],
                  condition: dimensionsDraft[1]?.condition || 'AND',
                },
              },
            })
          }
          setActiveSegment(undefined)
          setEditingDimension(null)
        }}
        onCancel={() => {
          setActiveSegment(undefined)
          setEditingDimension(null)
        }}
        segment={activeSegment}
        initialValues={editingDimension?.segments[0]}
        isEditing={!!editingDimension}
      />
    )

  return (
    <>
      <div className="p-4 ml-4">
        {customerGroup?.map((dim) => (
          <div key={dim.group_code}>
            <div className=" grid grid-cols-6 gap-4">{renderCustomerGroup(dim, false)}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default DimensionsCustomer

import React, { useContext, useEffect, useState } from 'react'
import { Select, Input, Button } from 'antd'
import { Formik, Form } from 'formik'
import axios from 'Utils/cj-axios'
import { intersection } from 'lodash'
import Criteria from './Criteria'
import { dimensionPopupContext } from './DimensionFilters/Dimensions.Popup'
import { useStoreActions, useStoreState } from 'easy-peasy'
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: {
    wait_time: number
    time_format: 'min' | 'hour' | 'day'
    conditions: string
  }
  onUpdateNode: any
  current: any
}

export type Segment = {
  condition?: string
  segments?: { code?: string; value?: string; type?: string }[]
}
export type Dimension = {
  condition?: string
  segments?: Segment[]
}

const NewWaitIn: React.FC<Props> = (props) => {
  const { onAddNewNode, value, onUpdateNode, current } = props
  const { criteria_groups } = useStoreState((state) => state.customerSegments)
  const { setState } = useStoreActions((action: any) => action.customerSegments)

  const defaultValue = { wait_time: 5, time_format: 'min', conditions: undefined }
  const onSubmit = (values: any) => {
    onAddNewNode && onAddNewNode('waitIn', { ...values, conditions: criteria_groups })
    onUpdateNode && onUpdateNode({ ...values, conditions: criteria_groups })
  }

  useEffect(() => {
    if (current?.value?.conditions) {
      setState({ key: 'criteria_groups', value: current?.value?.conditions })
    } else {
      setState({
        key: 'criteria_groups',
        value: [
          {
            dimension: {
              segments: [],
              condition: 'AND',
            },
            condition: 'AND',
          },
        ],
      })
    }
  }, [current])

  return (
    <div className="WaitComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item time-setter">
              <Input
                className="mr-2 w-1/2"
                onChange={handleChange}
                name="wait_time"
                value={values.wait_time}
              />
              <Select
                onChange={(value) => setFieldValue('time_format', value)}
                // name="time_format"
                className="time-setter-format w-1/2"
                value={values.time_format}
              >
                <Option value="min">mins</Option>
                <Option value="hour">hours</Option>
                <Option value="day">days</Option>
              </Select>
            </div>
            <div>Conditions</div>
            <Criteria
              defaultDimensions={current?.value?.conditions ?? value?.conditions}
            ></Criteria>
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default NewWaitIn

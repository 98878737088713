import React, { useState, useEffect } from 'react'
import { Select, InputNumber, Modal, Input, message } from 'antd'
import { connect } from 'react-redux'
import { Key } from 'rc-select/lib/interface/generator'
import TimeInput from 'Containers/DimensionFilters/DimensionGroups/Customer/Customer.SegmentScreen.TimeInput'
import { TREE_DIMENSIONS } from 'Containers/DimensionFilters/constants'
import TreeSelect from 'Containers/DimensionFilters/Inputs/CommonInputs/TreeSelect'
import useFetchDimensions from 'Containers/DimensionFilters/DimensionGroups/Customer/useFetchDimensions'

const { Option } = Select

interface Props {
  visible: boolean
  toggleDimensionModal: any
  addSegment: any
  dimensions: any
  onDimensionModalCancel: any
}

type AttributeType = {
  type?: string
  operator?: string
  value?: any
  timeOperator?: any
  time_value?: any
  count?: any
}

const UserAttributeModal2: React.FC<Props> = ({
  visible,
  toggleDimensionModal,
  onDimensionModalCancel,
  addSegment,
  dimensions,
}) => {
  const [attributes, setAttributes] = useState<AttributeType>({
    type: undefined,
    operator: undefined,
    value: undefined,
    timeOperator: undefined,
    time_value: undefined,
    count: undefined,
  })

  const selectedType =
    attributes && dimensions.find((item: { code: any }) => item.code === attributes.type)
  useEffect(() => {
    // value && setAttributes(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const { options, setKeyword } = useFetchDimensions({
    dimension_code: selectedType?.code,
  })

  const renderOptions = (optionType: string) => {
    return (
      (attributes.type &&
        selectedType?.[optionType]?.map(
          (item: {
            code: Key | undefined
            name:
              | boolean
              | React.ReactChild
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined
          }) => {
            return (
              <Option key={item.code || 0} value={item.code || ''}>
                {item.name}
              </Option>
            )
          }
        )) ||
      []
    )
  }

  const renderOptionsInput = () => {
    if (TREE_DIMENSIONS.includes(attributes?.type || ''))
      return (
        <TreeSelect
          values={attributes.value ? JSON.parse(attributes?.value) : []}
          onChange={(value) => {
            setAttributes({ ...attributes, value: JSON.stringify(value || '[]') })
          }}
          handleSearch={(keyword) => setKeyword(keyword)}
          options={options}
        />
      )

    return (
      <Input
        className="ant-input"
        placeholder="Value"
        onChange={(v) => {
          console.log(v.target.value)
          setAttributes({ ...attributes, value: v.target.value })
        }}
        value={attributes.value}
        // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        // parser={(value) => {
        //   if (value) {
        //     return value.replace(/\$\s?|(,*)/g, '')
        //   }
        // }}
        name="value"
        min={0}
      ></Input>
    )
  }

  const renderConfigContent = () => {
    return (
      <div className="CheckUserAttrForm">
        <div className="form-item type">
          <Select
            placeholder="Type"
            onChange={(v) => {
              setAttributes({
                operator: undefined,
                value: undefined,
                timeOperator: undefined,
                time_value: undefined,
                type: v,
              })
            }}
            value={attributes.type}
            // name="type"
          >
            {dimensions.map(
              (item: {
                code: Key | undefined
                name:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined
              }) => {
                return (
                  <Option key={item.code || 0} value={item.code || ''}>
                    {item.name}
                  </Option>
                )
              }
            )}
          </Select>
        </div>
        <div className="form-item">
          <Select
            onChange={(v) => setAttributes({ ...attributes, operator: v })}
            value={attributes.operator}
            placeholder="Operator"
            // name="operator"
          >
            {renderOptions('operator')}
          </Select>
        </div>
        <div className="form-item">{renderOptionsInput()}</div>

        {/* Time operator */}
        <div className="form-item">
          {selectedType?.timeOperator?.length ? (
            <div className="flex">
              <div className="mr-4">
                <Select
                  placeholder="When"
                  value={attributes?.timeOperator}
                  onChange={(v) => {
                    console.log(v)
                    setAttributes({ ...attributes, timeOperator: v })
                  }}
                >
                  {renderOptions('timeOperator')}
                </Select>
              </div>
              <div className="mr-4" style={{ width: 200 }}>
                <TimeInput
                  timeOperator={{
                    name: attributes.timeOperator,
                    values:
                      selectedType?.timeOperator?.find(
                        (item) => item.code === attributes.timeOperator
                      )?.values || {},
                  }}
                  value={attributes.time_value || ''}
                  onChange={(v) => {
                    if (v) {
                      setAttributes({ ...attributes, time_value: v })
                    }
                  }}
                  isEditing={true}
                />
              </div>
            </div>
          ) : null}
        </div>
        {selectedType?.extraField?.filter((e) => e?.code === 'count')?.length ? (
          <div>
            <span className="mr-2">count</span>
            <Input
              style={{ height: 40, borderRadius: 4, width: 100, marginRight: 8 }}
              placeholder="Value"
              value={attributes.count}
              onChange={(ev) => {
                setAttributes({ ...attributes, count: ev.target.value.toString() })
              }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  const onOk = () => {
    addSegment({
      audience: attributes,
    })
  }

  return (
    <Modal
      title="Add user attribute"
      className="CheckUserAttrModal"
      closable
      width={1200}
      onOk={() => onOk()}
      onCancel={() => onDimensionModalCancel()}
      visible={visible}
    >
      {renderConfigContent()}
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    dimensions: state.segmentation.dimensions,
  }),
  (dispatch: any) => ({
    addSegment: dispatch.segmentation.addSegment,
    toggleDimensionModal: dispatch.segmentation.toggleDimensionModal,
    onDimensionModalCancel: dispatch.segmentation.onDimensionModalCancel,
  })
)(UserAttributeModal2)

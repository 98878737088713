import React, { useEffect, useRef, useState } from 'react'
import { Select, Typography, Button } from 'antd'
import { Formik, Form } from 'formik'
import { useStoreState } from 'easy-peasy'

const { Option } = Select

interface Props {
  onAddNewNode: any
  value: { promotionId: string }
  onUpdateNode: any
}

const OutOfPromotionNode: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = { promotionId: '' }
  const { CJTree } = useStoreState((states) => states.customerJourney)

  const onSubmit = (values: { promotionId: string }) => {
    onAddNewNode && onAddNewNode('outOfPromotion', values)
    onUpdateNode && onUpdateNode(values)
  }

  function findNodesOfType(node, targetType) {
    let result: any = []

    // Check if the current node is of the target type
    if (node?.nodeType === targetType) {
      result?.push(node)
    }

    // If the node has children, recursively check them
    if (node?.childrenNodes && node?.childrenNodes.length > 0) {
      for (let child of node.childrenNodes) {
        result = result?.concat(findNodesOfType(child, targetType))
      }
    }

    return result
  }

  return (
    <div className="ReachabilityComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item">
              <Select
                showSearch
                value={values.promotionId}
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select a promotion"
                optionFilterProp="children"
                onChange={(value) => setFieldValue('promotionId', value)}
                filterOption={false} // Let the server handle the filtering
              >
                {findNodesOfType(CJTree, 'promotion')?.map((promotion) => (
                  <Option key={promotion?.value?.promotionId} value={promotion?.value?.promotionId}>
                    <div className=" flex gap-2 items-center">
                      <span
                        className=" rounded bg-gray-200 p-1 text-center"
                        style={{ minWidth: '32px' }}
                      >
                        {promotion?.alias}
                      </span>
                      <span>
                        {promotion?.value?.promotionName || promotion?.value?.promotionId}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default OutOfPromotionNode

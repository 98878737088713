import React from 'react'
import { QuestionCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import classNames from 'classnames'

const renderPrefix = (type) => {
  switch (type) {
    case 'reached':
      return <span>Reached: </span>
    case 'passed':
      return (
        <CheckCircleOutlined style={{ color: '#52C41A', fontWeight: 600, marginLeft: '4px' }} />
      )
    case 'failed':
      return (
        <CloseCircleOutlined style={{ color: '#F5222D', fontWeight: 600, marginLeft: '4px' }} />
      )
    case 'waiting':
      return <span style={{ fontWeight: 700 }}>Waiting: </span>
  }
}

const renderColor = (type) => {
  switch (type) {
    case 'reached':
      return '#000000'
    case 'failed':
      return '#F5222D'
    case 'passed':
      return '#52C41A'
    default:
      return '#000000'
  }
}

const NodeStats = ({
  type,
  value,
  setCJAnalyticsStates,
  nodeUuid,
  CJStates,
  CJAnalyticsStates,
}) => {
  // if (!['running'].includes(CJStates.status)) return null

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        setCJAnalyticsStates({
          key: 'selected_node_statistic',
          value: [{ key: type, label: type, value: value }],
        })
        setCJAnalyticsStates({
          key: 'node_id',
          value: nodeUuid,
        })
      }}
      style={{ color: renderColor(type) }}
      className={classNames(`node-stats ${type}`, { disabled: CJAnalyticsStates.loading[1] })}
    >
      {type === 'reached' ? (
        <>
          {renderPrefix(type)}
          <span className="ml-1 font-semibold">{value?.toLocaleString() || '--'}</span>
        </>
      ) : (
        <>
          <span className=" font-semibold">{value?.toLocaleString() || '--'}</span>
          {renderPrefix(type)}
        </>
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    CJStates: state.customerJourney,
    CJAnalyticsStates: state.CJAnalytics,
  }),
  (dispatch) => ({
    setCJStates: dispatch.customerJourney.setCJStates,
    setCJAnalyticsStates: dispatch.CJAnalytics.setCJAnalyticsStates,
  })
)(NodeStats)
